<template>
    <!--新增对话框-->
    <el-dialog
            :title="editForm.couponsId?'编辑优惠券':'新增优惠券'"
            :visible.sync="dialogVisible"
            width="800"
            :close-on-click-modal="false"
            :before-close="handleClose">

        <el-form :model="editForm" :rules="editFormRules" ref="editForm" label-width="100px" class="demo-editForm">
            <el-form-item label="名称" prop="couponsName" label-width="150px">
                <el-input v-model="editForm.couponsName" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="优惠用途" prop="newcomerCoupon" label-width="150px" >
                <el-radio-group v-model="editForm.newcomerCoupon">
                    <el-radio :label="1">新人</el-radio>
                    <el-radio :label="2">指定用户</el-radio>
<!--                    <el-radio :label="3">领取</el-radio>-->
                    <el-radio :label="4">分享</el-radio>
                  <el-radio :label="5">商城</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="数量限制" prop="isQuantityLimit" label-width="150px" v-if="editForm.newcomerCoupon==3">
                <el-radio-group v-model="editForm.isQuantityLimit">
                    <el-radio :label="1">限制</el-radio>
                    <el-radio :label="2">不限制</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="指定用户" prop="nickName" label-width="150px" v-if="editForm.newcomerCoupon==2">
                <el-input v-model="editForm.nickName" autocomplete="off" disabled>
                    <el-button slot="append"  @click="getUsersList()">选择用户</el-button>
                </el-input>
            </el-form-item>
            <el-form-item label="优惠规则类型" prop="rulesType" label-width="150px"  >
                <el-radio-group v-model="editForm.rulesType" @change="rulesTypeChange">
                    <el-radio :label="2">满减</el-radio>
                    <el-radio :label="1">无门槛</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="开始领取时间" prop="startTime" label-width="150px"  v-if="editForm.newcomerCoupon==3">
                <el-date-picker
                    v-model="editForm.receivingTime"
                    :picker-options="pickerOptions"
                    type="datetime"
                    default-time="00:00:00"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="选择开始领取时间">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="优惠券有效期" prop="endTime" label-width="150px">
                <el-date-picker
                        v-model="editForm.termValidity"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy年MM月dd日"
                        :picker-options="pickerOptions"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :default-time="['00:00:00', '23:59:59']">
                </el-date-picker>
            </el-form-item>
             <el-form-item label="优惠券数量" prop="amount" label-width="150px" v-if="editForm.newcomerCoupon==4&&editForm.isQuantityLimit==1">
                <el-input v-model="editForm.amount" autocomplete="off" type="number" @input="weihechangge($event)"></el-input>
            </el-form-item>
            <el-form-item label="满减条件" prop="satisfyFrom" label-width="150px" v-if="editForm.rulesType ==2" >
                <el-input v-model="editForm.satisfyFrom" autocomplete="off" type="number" @input="weihechangge($event)"></el-input>
            </el-form-item>
            <el-form-item label="满减抵扣值" prop="satisfyTo" label-width="150px" v-if="editForm.rulesType ==2">
                <el-input v-model="editForm.satisfyTo" autocomplete="off" type="number" @input="weihechangge($event)"></el-input>
            </el-form-item>
            <el-form-item label="无门槛金额" prop="voucher" label-width="150px" v-if="editForm.rulesType ==1">
                <el-input v-model="editForm.satisfyTo" autocomplete="off" type="number" @input="weihechangge($event)"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm('editForm')">保存</el-button>
                <el-button @click="resetForm('editForm')">重置</el-button>
            </el-form-item>
        </el-form>
        <GetUsers ref="getCouponsUsers" v-if="getCouponsUserssvisible" @refreshDataList="getUsers"></GetUsers>
    </el-dialog>
</template>

<script>
    import GetUsers from "./GetUsers"
    export default {
        name: "AddUpdateCoupons",
        components:{
            GetUsers
        },
        data(){
            return{
                pickerOptions:{                                 //禁用当前日期之前的日期
                    disabledDate(time) {
                        //Date.now()是javascript中的内置函数，它返回自1970年1月1日00:00:00 UTC以来经过的毫秒数。
                        return time.getTime() < Date.now() - 8.64e7;
                    },
                },
                editForm:{},
                avatar:'',
                fileList:[],
                urlList:[],
                newcomerCoupon:false,
                dialogVisible:false,
                getCouponsStoreGoodsvisible: false,
                getCouponsUserssvisible: false,
                editFormRules: {
                    couponsName: [
                        {required: true, message: '请输入名称', trigger: 'blur'}
                    ],
                    rulesType: [
                        {required: true, message: '请选择优惠规则类型', trigger: 'blur'}
                    ],
                    newcomerCoupon:[
                        {required: true, message: '请选择优惠用途', trigger: 'blur'}
                    ],
                    termValidity: [
                        {required: true, message: '请输入优惠券有效期', trigger: 'blur'}
                    ],
                    scopeApplication:[
                        {required: true, message: '请输入优惠券适用范围', trigger: 'blur'}
                    ],
                    classification: [
                        {required: true, message: '请选择作用', trigger: 'blur'}
                    ],
                },
            }
        },
        methods:{
            change(val) {
                this.editForm.content=val;
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.dialogVisible = false
                this.editForm = {}
            },
            handleClose() {
                this.resetForm('editForm')
            },
            getList(){
                this.getCouponsStoreGoodsvisible=true;
                this.$nextTick(()=>{
                    this.$refs.getCouponsRelGood.init(this.editForm.storeGoodsIdList);
                })
            },
            getUsersList(){
                this.getCouponsUserssvisible=true;
                this.$nextTick(()=>{
                    this.$refs.getCouponsUsers.init(this.editForm.userIdList);
                })
            },
            changeclassification(){
              if (this.editForm.classification==0){
                  this.editForm.storeGoodsIdList=[]
                  this.editForm.storeGoodsName=''
              }  else {
                  this.editForm.classificationType=undefined
              }
            },
            weihechangge(e){
                this.$forceUpdate()
            },
            getUsers(ids,nickName){
                this.editForm.userIdList=ids
                this.editForm.nickName=nickName
                this.$forceUpdate();
            },
            getStoreGoods(storIds,storeGoodsName){
                // console.log('ssdddd',item)
                this.editForm.storeGoodsIdList=storIds
                this.editForm.storeGoodsName=storeGoodsName
                this.$forceUpdate();
            },
            rulesTypeChange(){
                console.log("ws====>>")
                if (this.editForm.rulesType==0){
                    this.editForm.voucher=''
                }else {
                    this.editForm.satisfyFrom=''
                    this.editForm.satisfyTo=''
                }
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.editForm.newcomerCoupon==2){
                            if (this.editForm.userIdList==null||this.editForm.userIdList.length<=0){
                                this.$message({
                                    showClose: true,
                                    message:"请选择指定的用户",
                                    type: 'error',
                                });
                                return ;
                            }
                        }

                        if (this.editForm.rulesType==0){
                            if (this.editForm.satisfyFrom==undefined||this.editForm.satisfyFrom==''||this.editForm.satisfyFrom<=0){
                                this.$message({
                                    showClose: true,
                                    message:"请填写满减条件",
                                    type: 'error',
                                });
                                return ;
                            }
                            if (this.editForm.satisfyTo==undefined||this.editForm.satisfyTo==''||this.editForm.satisfyTo<=0){
                                this.$message({
                                    showClose: true,
                                    message:"请填写满减抵扣值",
                                    type: 'error',
                                });
                                return ;
                            }
                        }else {
                            console.log("ssdddrrftdxxx",this.editForm)
                            if (this.editForm.satisfyTo==undefined||this.editForm.satisfyTo==''||this.editForm.satisfyTo<=0){
                                this.$message({
                                    showClose: true,
                                    message:"请填写无门槛金额",
                                    type: 'error',
                                });
                                return ;
                            }
                        }
                        if (this.editForm.newcomerCoupon==1){
                            this.$axios.post('/admin/coupons/newcomerCoupon').then(res => {
                                if (res.data.newcomerCoupon==0){
                                    this.saveCoupons();
                                }else {
                                    this.$confirm('已经存在未过期的新人优惠券，确定将之前的新人优惠券作废吗', '提示',
                                        {confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'}).then(() =>
                                    {
                                        this.saveCoupons(formName);
                                    })
                                }
                                })
                        }else {
                            this.saveCoupons(formName);
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            saveCoupons(formName){
                var startTime='';
                var endTime='';
                if(this.editForm.termValidity!=undefined){
                    startTime=this.editForm.termValidity[0];
                    endTime=this.editForm.termValidity[1];
                }
                this.editForm.startTime=startTime
                this.editForm.endTime=endTime
                this.$axios.post('/admin/coupons/' + (this.editForm.couponsId?'updCoupons' : 'addCoupons'), this.editForm)
                    .then(res => {
                        this.$message({
                            showClose: true,
                            message: '恭喜你，操作成功',
                            type: 'success',
                            onClose:() => {
                                this.$emit('refreshDataList')
                            }
                        });
                        this.dialogVisible = false
                        this.resetForm(formName)
                    })
            },
            getCouponsCoverList(url,add) {
                if (add==1){
                    this.urlList.push(url)
                }else if (add==0){
                    if (this.urlList==null||this.urlList.length<1){
                        return;
                    }
                    this.urlList.some((item, i)=>{
                        if(item==url){
                            this.urlList.splice(i, 1)
                            //在数组的some方法中，若是return true，就会当即终止这个数组的后续循环
                            return true
                        }
                    })
                }
                this.editForm.couponsCover=this.urlList.join(",")
            },
            init(id){
                this.dialogVisible=true;
                if (id!=null&&id!=''&&id>0){
                    this.$axios.post('/admin/coupons/getCouponsInfo' ,{couponsId:id}).then(res => {
                        this.editForm = res.data
                        var termValidity=[];
                        if (res.data){
                            termValidity.push(res.data.startTime)
                            termValidity.push(res.data.endTime)
                            this.editForm.termValidity=termValidity;
                        }
                    })
                }else {
                    // this.editForm.rulesType=0;
                }
            },
        }
    }
</script>

<style scoped>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
